<script setup lang="ts">
  definePageMeta({
    layout: 'centered',
    middleware: () => {
      const user = useSupabaseUser()
      if (user.value) {
        navigateTo('/')
      }
    }
  })

  // Needed composables
  const router = useRouter()
  const user = useSupabaseUser()
  const { auth } = useSupabaseClient()
  const { addSuccessNotification } = useNotifications()
  
  // Redirecting to the dashboard (only when the `user.value` is available)
  watch(user, () => {
    console.log('user', user.value)
    nextTick(() => {
      if (user.value?.id) {
        const redirectPath = useCookie('sb-redirect-path')
        console.log('redirectPath', redirectPath.value)
        if (redirectPath.value) {
          navigateTo(redirectPath.value)
        } else {
          navigateTo('/')
        }
        addSuccessNotification({
          title: 'Connexion réussie',
          message: 'Vous êtes maintenant connecté à votre compte'
        })
      }
    })
  })

  // Informing the user whe he just logged out
  onMounted(() => {
    // Redirecting to the dashboard if the user is already logged in
    if (user.value?.id) {
      const redirectPath = useCookie('sb-redirect-path')
      console.log('redirectPath', redirectPath.value)
      if (redirectPath.value) {
        navigateTo(redirectPath.value)
      } else {
        navigateTo('/')
      }
      addSuccessNotification({
        title: 'Connexion réussie',
        message: 'Vous êtes maintenant connecté à votre compte'
      })
    }

    // Informing the user whe he just logged out
    const route = router.currentRoute.value
    if (route.query.logout === '1') {
      addSuccessNotification({
        title: 'Déconnexion réussie',
        message: 'Vous n\'êtes plus connecté à votre compte'
      })
      router.replace({ query: {} })
    }
  })

  // Form fields
  const email = ref<string | null>(null)
  const password = ref<string | null>(null)

  // Form variables
  const isLoading = ref<boolean>(false)
  const errorMessage = ref<string | null>(null)

  // Function called on the form submit event
  async function login(): Promise<void> {
    isLoading.value = true
    errorMessage.value = null

    if (!email.value || !password.value) {
      errorMessage.value = 'Les deux champs doivent être remplis'
      isLoading.value = false
      return
    }

    const { error } = await auth.signInWithPassword({
      email: email.value,
      password: password.value,
    })

    if (error) {
      errorMessage.value = error.message
      isLoading.value = false
      return
    }

    // The top-leveled watcher will redirect the user to the dashboard
  }
</script>

<template>
  <div>
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <Logo class="mx-auto h-12 w-auto" />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Se connecter</h2>
      <p class="mt-2 text-center text-sm text-gray-600 text-balance leading-5 px-4">
        Ou {{ ' ' }}        
        <NuxtLink href="/register" class="font-medium text-sky-blue-600 hover:text-sky-blue-500">
          s'inscrire à AppTrident
        </NuxtLink>
        si vous êtes client de la {{ ' ' }} 
        <NuxtLink href="https://methode-trident.com/" target="_blank" class="underline">
          Méthode Trident</NuxtLink>.
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form @submit.prevent="login" class="space-y-6" action="#" method="POST">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700"> Adresse email </label>
            <div class="mt-1">
              <input v-model="email" id="email" name="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-sky-blue-500 focus:border-sky-blue-500 sm:text-sm" />
            </div>            
            <p class="mt-1 text-xs text-gray-500">Même adresse email que votre achat de la Méthode Trident.</p>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700"> Mot de passe </label>
            <div class="mt-1">
              <input v-model="password" id="password" name="password" type="password" required autocomplete="current-password" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-sky-blue-500 focus:border-sky-blue-500 sm:text-sm" />
            </div>
          </div>

          <div class="flex items-center justify-start">
            <div class="text-sm">
              <NuxtLink href="/password-reset" class="font-medium text-sky-blue-600 hover:text-sky-blue-500">Mot de passe oublié ? </NuxtLink>
            </div>
          </div>

          <div>
            <Button :isLoading="isLoading" type="submit" class="w-full flex justify-center">Me connecter</Button>
            <p :class="errorMessage ? 'h-[20px]' : 'h-0'" class="transition-all duration-300 mt-2 text-sm text-red-600" id="form-error">{{ errorMessage }}</p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
